import theme from './theme';

const { widthXS, widthSM, widthMD, widthLG, sidebarWidth } = theme.metrics;

// Viewport query ranges
const v = {
  xs: {
    max: `(max-width: ${widthXS}px)`,
  },
  sm: {
    min: `(min-width: ${widthXS + 1}px)`,
    max: `(max-width: ${widthSM}px)`,
    maxWithLayout: `(max-width: ${widthSM + sidebarWidth}px)`,
  },
  md: {
    min: `(min-width: ${widthSM + 1}px)`,
    minWithLayout: `(min-width: ${widthSM + sidebarWidth + 1}px)`,
    max: `(max-width: ${widthMD}px)`,
  },
  lg: {
    min: `(min-width: ${widthMD + 1}px)`,
    max: `(max-width: ${widthLG}px)`,
  },
  xl: {
    min: `(min-width: ${widthLG + 1}px)`,
  },
};

// Standard queries
export const XS_QUERY = v.xs.max;
export const SM_QUERY = `${v.sm.min} and ${v.sm.max}`;
export const MD_QUERY = `${v.md.min} and ${v.md.max}`;
export const LG_QUERY = `${v.lg.min} and ${v.lg.max}`;
export const XL_QUERY = v.xl.min;

export const SM_AND_MD_QUERY = `${v.sm.min} and ${v.md.max}`;
export const SM_AND_UP_QUERY = v.sm.min;
export const SM_AND_DOWN_QUERY = v.sm.max;
export const MD_AND_DOWN_QUERY = v.md.max;
export const MD_AND_UP_QUERY = v.md.min;
export const LG_AND_UP_QUERY = v.lg.min;

// Queries including the layout
export const SM_LAYOUT_QUERY = `${v.sm.min} and ${v.sm.maxWithLayout}`;
export const SM_AND_DOWN_LAYOUT_QUERY = v.sm.maxWithLayout;
export const MD_AND_UP_LAYOUT_QUERY = v.md.minWithLayout;

// NOTE: To be deprecated after mobile block becomes obsolete
export const MOBILE_QUERY_LEGACY = `(max-width: ${widthXS + 166}px)`;

const MEDIA_QUERY = {
  XS_QUERY,
  SM_QUERY,
  MD_QUERY,
  LG_QUERY,
  XL_QUERY,
};

export default MEDIA_QUERY;
