import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import imgBackground from './img_gameplay_background.svg';
import imgSoooul from './img_soooul.png';
import imgStep301 from './img_step3_01.png';
import imgStep302 from './img_step3_02.png';
import imgStep4NFTGeneral01 from './img_dream-pass-nft_general_1.jpg';
import imgStep4NFTGeneral02 from './img_dream-pass-nft_general_2.jpg';
import imgStep4NFTGeneral03 from './img_dream-pass-nft_general_3.jpg';
import imgStep4NFTGeneral04 from './img_dream-pass-nft_general_4.jpg';
import imgStep4NFTVIP01 from './img_dream-pass-nft_vip_1.jpg';
import imgStep4NFTVIP02 from './img_dream-pass-nft_vip_2.jpg';
import imgStep4NFTVIP03 from './img_dream-pass-nft_vip_3.jpg';
import imgStep4NFTVIP04 from './img_dream-pass-nft_vip_4.jpg';
import imgBulletinYellow from '../../assets/bulletin_yellow.svg';
import iconPopup from '../../assets/icon_popup.svg';
import { XS_QUERY } from '../../configs/breakpoints';
import { Carousel } from './Carousel';
import { ExternalButton } from '../shared/ExternalButton';
import { useResponsive } from '../../hooks/useResponsive';
import { Step2Carousel } from './Step2Carousel';
import { ButtonGroup } from './ButtonGroup';
import { Disclaimer } from './Disclaimer';

const generalNFTs = [
  {
    key: 1,
    img: imgStep4NFTGeneral01,
  },
  {
    key: 2,
    img: imgStep4NFTGeneral02,
  },
  {
    key: 3,
    img: imgStep4NFTGeneral03,
  },
  {
    key: 4,
    img: imgStep4NFTGeneral04,
  },
];

const vipNFTs = [
  {
    key: 1,
    img: imgStep4NFTVIP01,
  },
  {
    key: 2,
    img: imgStep4NFTVIP02,
  },
  {
    key: 3,
    img: imgStep4NFTVIP03,
  },
  {
    key: 4,
    img: imgStep4NFTVIP04,
  },
];

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;
  background: url(${imgBackground});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media ${XS_QUERY} {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled.div`
  padding: 100px;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitleContainer = styled.div`
  color: white;
  margin-bottom: 48px;
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  font-color: white
  margin-bottom: 12px;
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${XS_QUERY} {
    flex-direction: column;
  }
`;

const StyledDescription = styled.div`
  color: white;
  width: 100%;

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @media ${XS_QUERY} {
      font-size: 32px;
      line-height: 48px;
      justify-content: center;
    }

    &:before {
      display: flex;
      align-items: center;
      content: '';
      height: 30px;
      width: 30px;
      margin-right: 12px;
      background: url(${imgBulletinYellow}) no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
    }
  }

  .jcc {
    justify-content: center;
    text-align: center;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    @media ${XS_QUERY} {
      font-size: 16px;
      line-height: 19px;
    }

    .pre {
      margin-right: 12px;
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      flex-basis: 30px;
      text-align: center;
      border-radius 15px;
      font-weight: 500;
      font-size: 20px;
      background-color: ${({ theme }) => theme.colors.brightDreamColor};
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;

    .link {
      border-bottom: 1px solid white;

      a {
        color: white;

        &:visited {
          color: white;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .q-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 2;

    @media ${XS_QUERY} {
      font-size: 16px;
    }
  }

  .contract {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 24px;
    display: flex;

    a {
      text-decoration-line: underline;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.hopeColor};
      margin-right: 6px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .q-answer {
    font-weight: 400;
    font-size: 16px;

    @media ${XS_QUERY} {
      font-size: 14px;
    }

    .link {
      border-bottom: 1px solid white;

      a {
        color: white;
        &:visited {
          color: white;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .list {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-left: 24px;

    li {
      list-style-type: decimal;
    }

    a {
      color: ${({ theme }) => theme.colors.hopeColor};
      border-bottom: 1px solid ${({ theme }) => theme.colors.hopeColor};

      &:hover {
        opacity: 0.8;
      }
    }
  }
  
  .emphasis {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.roseColor};
  }
`;

const StyledExternalButton = styled(ExternalButton)`
  margin: 24px 0 0 0;
  width: 300px;
    
  @media ${XS_QUERY} {
    background: rgb(22, 22, 26, 0.5);
  }
`;

const StyledSoulImage = styled.img`
  border: 1px solid white;
  margin-left: 24px;

  @media ${XS_QUERY} {
    margin 24px 0 0;
  }
`;

const StyledImageStep3Container = styled.div`
  display: flex;
  margin-top: 24px;

  @media ${XS_QUERY} {
    flex-direction: column;
  }

  .img {
    width: 100%;

    &:not(:last-child) {
      margin-right: 24px;
    }

    @media ${XS_QUERY} {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    img {
      width: 100%;
    }
  }
`;

const StyledNFTWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media ${XS_QUERY} {
    flex-direction: row;
  }
`;

const StyledImageStep4Container = styled.div`
  display: flex;
  
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media ${XS_QUERY} {
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  .img {
    width: 100%;
    border: 1px solid white;

    &:not(:last-child) {
      margin-right: 24px;
    }

    @media ${XS_QUERY} {
      &:not(:last-child) {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }

    img {
      width: 100%;
    }
  }
`;



const Gameplay = () => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const showSurveyLink = true;
  const surveyLink = new Date().getTime() >= 1670255400000
    ? 'https://metaform.xyz/user/637af47541ac4d16253d8c39'
    : 'https://metaform.xyz/user/637f84e441ac4d16255e1855';

  return (
    <StyledContainer id="gameplay">
      <StyledContent>
        <StyledTitleContainer>
          <StyledTitle>{t('How to Engage')}</StyledTitle>
          <StyledContentContainer>
            <Carousel />
            <StyledDescription>
              <div className="title">Step 1</div>
              <div className="sub-title">
                <span className="pre">1</span>
                {t('Collect Dream Pass NFT')}
              </div>
              <div className="description">
                {t('Dream Pass NFT will launch on')}&nbsp;
                <span className="link">
                  <a
                    href="https://www.thundercore.com/?lang=zh-Hant"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t('ThunderCore Chain')}
                  </a>
                </span>
              </div>
              <div className="q-title">{t('How to get a Dream Pass VIP NFT?')}</div>
              <div className="contract">
                <a
                  href="https://viewblock.io/thundercore/address/0x2e95dd344b0965c413b0642c61a294bf58cfbad9"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  CONTRACT ADDRESS
                </a>
                <img alt="icon popup" src={iconPopup} />
              </div>
              <div className="q-answer">
                {t('Any purchase of NFT Taipei ticket will come with a Dream Pass VIP NFT. It will be airdropped to your wallet on 12/7')}
              </div>
              <StyledExternalButton href="https://www.accupass.com/event/2211060922121075071251">
                {t('BUY NFT TAIPEI TICKET NOW')}
              </StyledExternalButton>
              <br />
              <div className="q-title">
                {t('How to get a Dream Pass General NFT?')}
              </div>
              <div className="contract">
                <a
                  href="https://viewblock.io/thundercore/address/0x1e6d3aa2cb7508f1fbcdc1fbb95cfb0215a4a175"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  CONTRACT ADDRESS
                </a>
                <img alt="icon popup" src={iconPopup} />
              </div>
              <div className="q-answer">
                {t('To welcome everyone to get in on the fun, follow the next few steps, and we’ll airdrop you a Dream Pass General NFT')}
              </div>
              <br />
              <ol className="list">
                <li>
                  <a
                    href="https://ttlink.site/aHR0cHM6Ly9tZXRhZm9ybS54eXovdXNlci82MzdhZjQ3NTQxYWM0ZDE2MjUzZDhjMzk="
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t('Download TT Wallet')}&nbsp;
                  </a>
                  <img alt="icon popup" src={iconPopup} />
                  {t('or Metamask')}</li>
                <li>{t('Go to MetaCRM and fill out a survey')}</li>
              </ol>
              <br />
              <br />
              <div className="emphasis">
                {t('*** There are only 9,000 Dream Pass General NFTs. Sign up now before they run out!***')}
              </div>
              {
                showSurveyLink && (
                  <StyledExternalButton href={surveyLink}>
                    {t('SURVEY LINK')}
                  </StyledExternalButton>
                )
              }
            </StyledDescription>
          </StyledContentContainer>
        </StyledTitleContainer>
      </StyledContent>
      <StyledContent>
        <StyledContentContainer>
          <StyledDescription>
            <div className="sub-title">
              <span className="pre">2</span>
              {t('Get a Soulbound Token from SOOOUL!')}
            </div>
            <div className="q-answer">
              {t('Your Soulbound Token journeys with you in and out of Web3. It captures your IRL engagements and transforms them into NFT traits along with added utility. The more you engage with it, the more traits you accumulate and the richer your SOOOUL becomes.')}
            </div>
            <br />
            <div className="q-answer">
              {t('The Soulbound token will be airdropped on 12/9 for both VIP members and basic members for free. All owners of the Dream Pass after the initial drop date will have another opportunity on 12/15 to receive their Soulbound token for free.')}
            </div>
            <br />
            <div className="q-answer">
              {t('The Soulbound token will only work in conjunction with the Dream Pass. If your Dream Pass is transferred/sold, your Soulbound token will not upgrade.')}
            </div>
            <br />
            <StyledExternalButton href="https://www.soooul.xyz/nft-taipei-promo">
              {t('EXPLORE SOOOUL')}
            </StyledExternalButton>
          </StyledDescription>
          <StyledSoulImage src={imgSoooul} />
        </StyledContentContainer>
      </StyledContent>
      <StyledContent id="gameplay_step2">
        <StyledDescription>
          <div className={`${isMobile ? 'title' : 'title jcc'}`}>Step 2</div>
          <div className="sub-title jcc">
            {t('Select a campaign to redeem your Dream Pass NFT!（STARTING ON 12/9 00:00）')}
          </div>
          <div className="q-answer jcc">
            {t('If you are using TT Wallet: go to your NFT collection and click “Redeem”')}
          </div>
          <Step2Carousel type="tt" />
          <div className="q-answer jcc">
            {t('If you are using Metamask')}
          </div>
          <div className="q-answer jcc">
            {t('a.Web: click “Redeem” button going to RE:DREAMER’s redeem page, and click “Specific event”')}
          </div>
          <div className="q-answer jcc">
            {t('b.Mobile: click “Copy link” button, and follow the steps below.')}
          </div>
          <ButtonGroup text="https://passport.redreamer.io" />
          <Step2Carousel type="metamask" />
        </StyledDescription>
      </StyledContent>
      <StyledContent>
        <StyledDescription>
          <div className="title jcc">Step 3</div>
          <div className="sub-title jcc">
            {t('Enjoy perks from Dream Pass NFT!')}
          </div>
          <div className="q-answer jcc">
            {t('Show QR Codes at checkouts, insert Promo Codes at related applications, or click on the Links to complete redemption.')}
          </div>
        </StyledDescription>
        <StyledImageStep3Container>
          <div className="img">
            <img alt="step3 example 1" src={imgStep301} />
          </div>
          <div className="img">
            <img alt="step3 example 2" src={imgStep302} />
          </div>
        </StyledImageStep3Container>
      </StyledContent>
      <StyledContent>
        <StyledDescription>
          <div className="title jcc">Step 4</div>
          <div className="sub-title jcc">
            {t('Each redemption will transform your Dream Pass NFT!')}
          </div>
          <div className="q-answer jcc">
            {t('Dream Pass - Dynamic NFT powered by')}&nbsp;
            <span className="link">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScYSMZzOBm0dWMcKFByrrDT2I3XA0Fiktcj453slOJ_qznaYQ/viewform"
                target="_blank"
                rel="noreferrer noopener"
              >
                Dynamics
              </a>
            </span>
          </div>
        </StyledDescription>
        <StyledNFTWrapper>
          <StyledImageStep4Container>
            {
              generalNFTs.map((n) => (
                <div className="img" key={n.key}>
                  <img alt="dynamic NFT" src={n.img} />
                </div>
              ))
            }
          </StyledImageStep4Container>
          <StyledImageStep4Container>
            {
              vipNFTs.map((n) => (
                <div className="img" key={n.key}>
                  <img alt="dynamic NFT" src={n.img} />
                </div>
              ))
            }
          </StyledImageStep4Container>
        </StyledNFTWrapper>
      </StyledContent>
      <StyledContent>
        <Disclaimer />
      </StyledContent>
    </StyledContainer>
  );
};

export default Gameplay;
