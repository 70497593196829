import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import imgLogo from '../../assets/img_redreamer_logo.svg';
import iconDiscord from '../../assets/icon_discord.svg';
import iconTwitter from '../../assets/icon_twitter.svg';
import iconInstagram from '../../assets/icon_instagram.svg';
import iconFacebook from '../../assets/icon_facebook.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 24px;
  padding: 0 100px;

  @media ${XS_QUERY} {
    padding: 0;
    flex-direction: column;
  }
`;

const StyledLogo = styled.a`
  display: block;
  width: 150px;

  @media ${XS_QUERY} {
    margin-bottom: 32px;
  }

  img {
    width: 150px;
  }

`;

const StyledTextGroup = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  @media ${XS_QUERY} {
    margin-bottom: 32px;
  }

  div {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
`;

const StyledIconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  a {
    display: block;
    height: 32px;

    &:hover {
      opacity: 0.8;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }

    img {
      height: 32px;
    }
  }
`;

const list = [
  {
    key: 'twitter',
    default: iconTwitter,
    hidden: false,
    url: 'https://twitter.com/REDREAMER_Lab',
  },
  {
    key: 'discord',
    default: iconDiscord,
    hidden: false,
    url: 'https://discord.com/invite/JHWFFQKCYX',
  },
  {
    key: 'instagram',
    default: iconInstagram,
    hidden: false,
    url: 'https://www.instagram.com/redreamer.io',
  },
  {
    key: 'facebook',
    default: iconFacebook,
    hidden: false,
    url: 'https://www.facebook.com/redreamer.io',
  },
];

const FooterInfo = () => {
  return (
    <StyledContainer>
      <StyledLogo
        href="https://www.redreamer.io"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img alt="redreamer logo" src={imgLogo} />
      </StyledLogo>
      <StyledIconGroup>
      {
        list.map((l) => !l.hidden && (
          <a
            key={l.key}
            href={l.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img alt={l.key} src={l.default} />
          </a>
        ))
      }
      </StyledIconGroup>
    </StyledContainer>
  );
};

export default FooterInfo;
