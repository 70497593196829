import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import imgBackground from './img_campaign_background.svg';
import imgSyntrend from './img_event_partners_syntrend.svg';
import imgSpace from './img_event_partners_space.svg';
import imgECLife from './img_event_partners_eclife.svg';
import imgBackbone from './img_event_partners_backbone.svg';
import imgWeMo from './img_event_partners_wemo.svg';
import imgChillnow from './img_event_partners_chillnow.svg';
import imgHalfWay from './img_event_partners_halfway.svg';
import imgFomoasia from './img_event_partners_fomoasia.svg';
import imgTaiHu from './img_event_partners_taihu.svg';
import imgSundayLab from './img_event_partners_sundaylab.svg';
import imgRTM from './img_event_partners_rtm.svg';
import imgAstroGator from './img_event_partners_astrogator.svg';
import imgBBL from './img_event_partners_bbl.svg';
import imgKKDay from './img_event_partners_kkday.svg';
import imgSecuX from './img_event_partners_secux.svg';
import imgSparkLabs from './img_event_partners_sparklabs.svg';
import imgHeiJiaJia from './img_event_partners_heijiajia.svg';
import imgACPay from './img_event_partners_acpay.svg';
import imgICarry from './img_event_partners_icarry.svg';
import imgTurnCloud from './img_cohost_turncloud.svg';
import imgPilr from './img_media_partners_pilr.svg';


import imgThunderCore from './img_tech_partners_thundercore.svg';
import imgDynamics from './img_tech_partners_dynamics.svg';
import imgMetaCRM from './img_tech_partners_metacrm.svg';
import imgSoooul from './img_tech_partners_soooul.svg';


const StyledContainer = styled.div`
background-color: black;
position: relative;
width: 100%;
margin: auto;
background: url(${imgBackground});
background-size: cover;
background-position: top;
background-repeat: no-repeat;

@media ${XS_QUERY} {
  width: 100%;
  overflow: hidden;
}
`;

const StyledContent = styled.div`
  padding: 100px;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: white;
  margin-bottom: 24px;

  @media ${XS_QUERY} {
    margin-bottom: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
`;

const StyledIcons = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 84px;
  }

  @media ${XS_QUERY} {
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  img {
    margin: 0 12px;

    @media ${XS_QUERY} {
      height: 36px;
      margin-bottom: 12px;
    }
  }
`;

const events = [
  {
    name: 'Syntrend',
    img: imgSyntrend,
  },
  {
    name: 'Space',
    img: imgSpace,
  },
  {
    name: 'ECLife',
    img: imgECLife
  },
  {
    name: 'Backbone',
    img: imgBackbone,
  },
  {
    name: 'WeMo',
    img: imgWeMo,
  },
  {
    name: 'ChillNow',
    img: imgChillnow,
  },
  {
    name: 'HalfWay',
    img: imgHalfWay,
  },
  {
    name: 'FomoAsia',
    img: imgFomoasia
  },
  {
    name: 'TaiHu',
    img: imgTaiHu
  },
  {
    name: 'SundayLab',
    img: imgSundayLab
  },
  {
    name: 'RTM',
    img: imgRTM
  },
  {
    name: 'AstroGator',
    img: imgAstroGator
  },
  {
    name: 'BBL',
    img: imgBBL
  },
  {
    name: 'kkday',
    img: imgKKDay
  },
  {
    name: 'SecuX',
    img: imgSecuX
  },
  {
    name: 'SparkLabs',
    img: imgSparkLabs
  },
  {
    name: 'HeiJiaJia',
    img: imgHeiJiaJia,
  },
  {
    name: 'ACPay',
    img: imgACPay,
  },
  {
    name: 'iCarry',
    img: imgICarry,
  },
];

const techs = [
  {
    name: 'Dynamics',
    img: imgDynamics,
  },
  {
    name: 'Soooul',
    img: imgSoooul,
  },
];

const hosts = [
  {
    name: 'ThunderCore',
    img: imgThunderCore,
  },
  {
    name: 'MetaCRM',
    img: imgMetaCRM,
  },
  {
    name: 'TurnCloud',
    img: imgTurnCloud,
  },
];

const media = [
  {
    name: 'Pilr',
    img: imgPilr,
  }
];

const Partners = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer id="partners">
      <StyledContent>
        <StyledTitle>{t('Co-host')}</StyledTitle>
        <StyledIcons>
          {
            hosts.map((e) => (
              <img key={e.name} alt={e.name} src={e.img} />
            ))
          }
        </StyledIcons>
        <StyledTitle>{t('Event Partners')}</StyledTitle>
        <StyledIcons>
          {
            events.map((e) => (
              <img key={e.name} alt={e.name} src={e.img} />
            ))
          }
        </StyledIcons>
        <StyledTitle>{t('Tech Partners')}</StyledTitle>
        <StyledIcons>
          {
            techs.map((e) => (
              <img key={e.name} alt={e.name} src={e.img} />
            ))
          }
        </StyledIcons>
        <StyledTitle>{t('Media Partners')}</StyledTitle>
        <StyledIcons>
          {
            media.map((e) => (
              <img key={e.name} alt={e.name} src={e.img} />
            ))
          }
        </StyledIcons>
      </StyledContent>
    </StyledContainer>
  );
};

export default Partners;