import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'use-onclickoutside';
import {
  StyledHeader,
  StyledNav,
  StyledContainer,
  StyledImage,
  StyledGroup,
  StyledItem,
  StyledRedreamerButton,
  StyledBackDrop,
  StyledDropdown,
  StyledDropdownContent,
} from "./Styles";
import logoImage from "../../assets/img_logo_dreampass.svg";
import iconEarth from "../../assets/icon_earth.svg";
import { useResponsive } from "../../hooks/useResponsive";
import { ToggleIcon } from "./ToggleIcon";

const Navbar = () => {
  const { isSmallerThanTablet } = useResponsive();
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { t, i18n } = useTranslation()

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  useOnClickOutside(dropdownRef, () => setDropdown(false));

  return (
    <>
      {isSmallerThanTablet && open && <StyledBackDrop hasBackDrop onClick={() => {setOpen(false)}}/>}
      <StyledHeader>
        <StyledNav>
          <StyledContainer>
            <StyledImage alt="redreamer logo" src={logoImage} />
            {isSmallerThanTablet && (
              <ToggleIcon onClick={handleClick} open={open} />
            )}
            <StyledGroup isSmallerThanTablet={isSmallerThanTablet} open={open}>
              <StyledItem>
                <a href="#campaigns">{t('Dream Pass Campaigns')}</a>
              </StyledItem>
              <StyledItem>
                <a href="#gameplay">{t('How to Engage')}</a>
              </StyledItem>
              <StyledItem>
                <a href="#partners">{t('Partners')}</a>
              </StyledItem>
              <StyledItem>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.nft-taipei.com"
                >
                  NFT Taipei
                </a>
              </StyledItem>
              <StyledItem>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://nftmall.io/collection/thundercore/0x2e95dd344b0965c413b0642c61a294bf58cfbad9"
                >
                  VIP NFT 
                </a>
              </StyledItem>
              <StyledItem>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://nftmall.io/collection/thundercore/0x1e6d3aa2cb7508f1fbcdc1fbb95cfb0215a4a175"
                >
                  General NFT 
                </a>
              </StyledItem>
              <StyledRedreamerButton>
                <a
                  href="#gameplay_step2"
                >
                  {t('Redeem')}
                </a>
              </StyledRedreamerButton>
              {!isSmallerThanTablet && (
                <StyledItem ref={dropdownRef}>
                  <StyledDropdown onClick={() => {setDropdown(!dropdown)}}>
                    <img alt="lang icon" src={iconEarth} />
                  </StyledDropdown>
                  {
                    dropdown && (
                      <StyledDropdownContent>
                        <div
                          className={`item ${i18n.resolvedLanguage === 'en' && 'active'}`}
                          onClick={() => {i18n.changeLanguage('en')}}
                        >
                          English
                        </div>
                        <div
                          className={`item ${i18n.resolvedLanguage === 'zh-Hant' && 'active'}`}
                          onClick={() => {i18n.changeLanguage('zh-Hant')}}
                        >
                          中文
                        </div>
                      </StyledDropdownContent>
                    )
                  }
                </StyledItem>)
              }
              {isSmallerThanTablet && (
                <StyledDropdownContent>
                  <div
                    className={`item ${i18n.resolvedLanguage === 'en' && 'active'}`}
                    onClick={() => {i18n.changeLanguage('en')}}
                  >
                    English
                  </div>
                  <div
                    className={`item ${i18n.resolvedLanguage === 'zh-Hant' && 'active'}`}
                    onClick={() => {i18n.changeLanguage('zh-Hant')}}
                  >
                    中文
                  </div>
                </StyledDropdownContent>
              )}
            </StyledGroup>
          </StyledContainer>
        </StyledNav>
      </StyledHeader>
    </>
  );
};

export default Navbar;
