import styled from 'styled-components';
import imgBulletinPurple from '../../assets/bulletin_purple.svg';
import imgBulletinYellow from '../../assets/bulletin_yellow.svg';
import iconLocation from '../../assets/icon_location.svg';
import { SM_AND_DOWN_QUERY } from '../../configs/breakpoints';

const StyledWrapper = styled.div`
  padding: 0px;

  @media ${SM_AND_DOWN_QUERY} {
    padding: 0;
  }
`;

const StyledContainer = styled.div`
  border: 1px solid white;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  @media ${SM_AND_DOWN_QUERY} {
    width: 100%;
  }
`;

const StyledImageContainer = styled.div`
  border-bottom: 1px solid white;
  width: 100%;

  img {
    width: 100%;
  }
`;

const StyledContent = styled.div`
  padding: 24px;
  color: white;
  height: 350px;
  overflow: hidden;

  .title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px;

    img {
      margin-right: 6px;
    }
  }

  .description {
    font-weight: 400;
    height: 250px;
    font-size: 16px;
    line-height: 24px;
    overflow: auto;
  }

  .location {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: ${({ theme }) => theme.colors.hopeColor};

    &:before {
      display: block;
      width: 24px;
      min-width: 24px;
      content: '';
      background: url(${iconLocation});
      background-repeat: no-repeat;
      background-position-y: 50%;
      margin-right: 6px;
      min-width: 24px;
    }
    color: ${({ theme }) => theme.colors.hopeColor};
  }
`;

export const Card = ({
  image, title, dotColor = 'purple', description, location,
}) => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledImageContainer>
          <img alt="campaign" src={image} />
        </StyledImageContainer>
        <StyledContent>
          <div className="title">
            <img
              alt="title"
              src={
                dotColor === "purple" ? imgBulletinPurple : imgBulletinYellow
              }
            />
            <span>{title}</span>
          </div>
          <div className="description">
            {description}
            <ol>
              <li className="location">{location}</li>
            </ol>
          </div>
        </StyledContent>
      </StyledContainer>
    </StyledWrapper>
  );
};
