import React from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';
import logoImage from "../../assets/img_logo_dreampass.svg";
import bannerNew from './banner_new.png';
import bannerNewMobile from './banner_new_mobile.png';
import imgWords from "./img_words.svg";
import { useResponsive } from "../../hooks/useResponsive";

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;

  @media ${XS_QUERY} {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled.div`
  display: flex;
  padding: 100px;
  justify-content: space-between;
  align-items: center;

  @media ${XS_QUERY} {
    padding: 24px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

`;

const StyledLogo = styled.div`
  width: 100%;
  margin-right: 48px;

  @media ${XS_QUERY} {
    margin-right: 0;
  }
  img {
    width: 100%;
  }
`;

const StyledImageWords = styled.img`
  width: 400px;
  margin-bottom: 24px;

  @media ${XS_QUERY} {
    width: 100%;
  }
`;

const StyledText = styled.div`
  color: white;
  min-width: 350px;

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    @media ${XS_QUERY} {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

const StyledBannerNew = styled.img`
  width: 100%;
}
`;

const Banner = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  return (
    <StyledContainer>
      <StyledContent>
        <StyledLogo>
          <img alt="logo" src={logoImage} />
        </StyledLogo>
        <StyledText>
          <StyledImageWords
            alt="From Taipei to the moon, your dream will come true."
            src={imgWords}
          />
          <div className="description">
            {t('Reimagine NFT redemption with a dynamic experience. Dream Pass NFT by RE:DREAMER is a unique event pass that grants exclusive perks in real life during NFT Taipei. Designed dynamically, the dNFT transforms with every redemption creating a never-before experience.')}
          </div>
        </StyledText>
      </StyledContent>
      <StyledBannerNew
        src={isMobile ? bannerNewMobile : bannerNew}
      />
    </StyledContainer>
  );
};

export default Banner;