import styled from 'styled-components';
import { useResponsive } from '../../hooks/useResponsive';
import { XS_QUERY } from '../../configs/breakpoints';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgTTWallet1 from './TT-wallet_1.jpg';
import imgTTWallet2 from './TT-wallet_2.jpg';
import imgTTWallet3 from './TT-wallet_3.jpg';
import imgTTWallet4 from './TT-wallet_4.jpg';
import imgTTWallet5 from './TT-wallet_5.jpg';
import imgTTWallet6 from './TT-wallet_6.jpg';
import imgMetaMask1 from './Metamask_1.jpg';
import imgMetaMask2 from './Metamask_2.jpg';
import imgMetaMask3 from './Metamask_3.jpg';
import imgMetaMask4 from './Metamask_4.jpg';
import imgMetaMask5 from './Metamask_5.jpg';
import imgMetaMask7 from './Metamask_7.jpg';
import imgMetaMask8 from './Metamask_8.jpg';
import imgMetaMask9 from './Metamask_9.jpg';
import imgMetaMask10 from './Metamask_10.jpg';

const StyledContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledContent = styled.div`
  position: relative;
  width: 100%;
  padding: 36px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;

  margin-bottom: 48px;

  @media ${XS_QUERY} {
    padding: 0px;
    border: none;
    &:not(:last-child) {
      margin-bottom: 24p;
    }
  }


`;

const StyledTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  border-bottom: 2px solid white;
  margin-bottom: 24px;
`;

const StyledImageContainer = styled.div`
  width: 100%;

  padding: 24px;

  @media ${XS_QUERY} {
    padding: 6px;
  }

  .image {
    width: 100%;
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-dots {
    li {
      button {
        &:before {
          color: white;
        }
      }
    }
    li.slick-active {
      button {
        &:before {
          color: white;
        }
      }
    }
  }
`;

const sliderSettings = {
  speed: 500,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3
};

export const Step2Carousel = ({ type = 'tt' }) => {
  const { isMobile } = useResponsive();
  if (isMobile) {
    sliderSettings.slidesToShow = 2;
    sliderSettings.slidesToScroll = 2;
  } else {
    sliderSettings.slidesToShow = 3;
    sliderSettings.slidesToScroll = 3;
  }

  return (
    <StyledContainer>
      {type === "tt" && (
        <StyledContent>
          <StyledTitle>TT Wallet</StyledTitle>
          <StyledSlider {...sliderSettings}>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet1} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet2} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet3} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet4} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet5} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="TT Wallet Step" src={imgTTWallet6} />
            </StyledImageContainer>
          </StyledSlider>
        </StyledContent>
      )}
      {type === "metamask" && (
        <StyledContent>
          <StyledTitle>MetaMask</StyledTitle>
          <StyledSlider {...sliderSettings}>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask1} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask2} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask3} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask4} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask5} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask7} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask8} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask9} />
            </StyledImageContainer>
            <StyledImageContainer>
              <img className="image" alt="MetaMask Step" src={imgMetaMask10} />
            </StyledImageContainer>
          </StyledSlider>
        </StyledContent>
      )}
    </StyledContainer>
  );
};