import { useMediaQuery } from 'react-responsive';
import { XS_QUERY, SM_QUERY, SM_AND_DOWN_QUERY } from '../configs/breakpoints';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ query: `${XS_QUERY}` });
  const isTablet = useMediaQuery({ query: `${SM_QUERY}` });
  const isSmallerThanTablet = useMediaQuery({ query: `${SM_AND_DOWN_QUERY}` });

  return {
    isMobile,
    isSmallerThanTablet,
    isTablet,
  };
};
