import React from 'react';
import styled from 'styled-components';
import iconClose from "../../assets/img_close.svg";
import iconHamburger from "../../assets/img_hamburger.svg";

const StyledContainer = styled.div`
  width: 18px;
  height: 18px;
`;

export const ToggleIcon = ({ open, onClick }) => {
  return (
    <StyledContainer onClick={onClick}>
      {open ? (
        <img alt="close menu" src={iconClose} />
      ) : (
        <img alt="open menu" src={iconHamburger} />
      )}
    </StyledContainer>
  );
};
