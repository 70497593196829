import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Carousel3D from 'react-spring-3d-carousel';
import { config } from "react-spring";
import { XS_QUERY } from '../../configs/breakpoints';
import imgDreamPassYellow from '../../assets/img_dreamer_pass_yellow.jpg';
import imgDreamPassPurple from '../../assets/img_dreamer_pass_purple.jpg';

const StyledCarouselContainer = styled.div`
  width: 100%;
  height: 500px;
  margin-right: 48px;

  @media ${XS_QUERY} {
    margin: 0px 0px 24px 0px;
    height: 100vw;
  }

  div > div {
    &:nth-child(odd) {
      opacity: 0.5!important;
    }
  }
`;

const StyledImage = styled.img`
  object-fit: contain;

  &&& {
    width: ${({ w }) => 400 * w / 740}px;
    object-fit: contain;
  }

  @media ${XS_QUERY} {
    &&& {
      width: 50vw;
    }
  }
`;

const defaultState = {
  goToSlide: 0,
  offsetRadius: 2,
  showNavigation: false,
  config: config.gentle
};

/*
export class Carousel extends Component {
  state = defaultState;

  slides = [
    {
      key: 1,
      content: <img src={imgDreamPassYellow} alt="dreamer pass" />
    },
    {
      key: 2,
      content: <img src={imgDreamPassPurple} alt="dreamer pass" />
    },
    {
      key: 3,
      content: <img src={imgDreamPassYellow} alt="dreamer pass" />
    },
    {
      key: 4,
      content: <img src={imgDreamPassPurple} alt="dreamer pass" />
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState((s) => ({ goToSlide: index })) };
  });

  render() {
    return (
      <div style={{ width: "80%", height: "500px", margin: "0 auto" }}>
        <Carousel3D
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}
*/

export const Carousel = () => {
  const ref = useRef();
  const [w, setW] = useState();
  const [state, setState] = useState(defaultState)

  useLayoutEffect(() => {
    if (ref) {
      setW((ref.current.getBoundingClientRect() || {}).width); 
    }
  }, [])

  const slides = [
    {
      key: 1,
      content: <StyledImage w={w} src={imgDreamPassYellow} alt="dreamer pass" />
    },
    {
      key: 2,
      content: <StyledImage w={w}  src={imgDreamPassPurple} alt="dreamer pass" />
    },
    {
      key: 3,
      content: <StyledImage w={w}  src={imgDreamPassYellow} alt="dreamer pass" />
    },
    {
      key: 4,
      content: <StyledImage w={w} src={imgDreamPassPurple} alt="dreamer pass" />
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setState((s) => ({ ...s, goToSlide: index })) };
  });

  return (
    <StyledCarouselContainer ref={ref}>
      <Carousel3D
        slides={slides}
        goToSlide={state.goToSlide}
        offsetRadius={state.offsetRadius}
        showNavigation={state.showNavigation}
        animationConfig={state.config}
      />
    </StyledCarouselContainer>
  );
};
