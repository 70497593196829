import React from 'react';
import styled from 'styled-components';
import imgRightTop from './right_top_arrow.svg';

const StyledCollapseButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  padding: 12px;
  height: 60px;
  width: 200px;
  margin: auto;
  margin-top: 48px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.greyColor}
  }

  span {
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    a {
      color: inherit;
      &:visited {
        color: inherit;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.greyColor};
    }
  }
`;


const ExternalButton = ({ className, children, onClick = () => {}, href = '', }) => {
  return (
    <StyledCollapseButton
      className={className}
      onClick={onClick}
      as={ href ? 'a' : 'div'}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      <span>
        {children}
      </span>
      <img alt="right-top arrow" src={imgRightTop} />
    </StyledCollapseButton>
  );
};

export default ExternalButton;