import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { XS_QUERY, SM_AND_DOWN_QUERY } from '../../configs/breakpoints';
import { ExternalButton } from '../shared/ExternalButton';
import { Card } from './Card';
import campaignImage1 from './img_campaigns_syntrend.jpg';
import campaignImage2 from './img_campaign_wemo.jpg';
import campaignImage3 from './img_campaign_halfway.jpg';
import campaignImage4 from './img_campaign_kkday.jpg';
import campaignImage5 from './img_campaign_taihu.jpg';
import campaignImage6 from './img_campaign_heijiajia.jpg';
import campaignImage7 from './img_campaign_vip-mystery-box.jpg';
import campaignImage8 from './img_campaign_general-mystery-box.jpg';
import campaignCloneX from './img_campaign_clonex.jpg';
import campaignTravelverse from './img_campaign_travelverse.jpg';
import campaignAstroGater from './img_campaign_astrogater.jpg';
import campaignICarry from './img_campaign_icarry.jpg';
import campaignDrunkWolverine from './img_campaigns_DrunkWolverine.jpg';
import placeholderImage from './img_campaigns_more-to-come.jpg';

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: auto;
  background: black;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media ${XS_QUERY} {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledContent = styled.div`
  padding: 100px;
  width: 100%;

  @media ${XS_QUERY} {
    padding: 24px;
  }
`;

const StyledTitleContainer = styled.div`
  color: white;
  margin-bottom: 48px;
`;

const StyledTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  font-color: white
  margin-bottom: 12px;

  @media ${XS_QUERY} {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
`;

const StyledDescription = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media ${XS_QUERY} {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
`;

const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
  justify-items: space-between;

  @media ${SM_AND_DOWN_QUERY} {
    grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
  }
`;

const DOT_COLOR = {
  purple: 'purple',
  yellow: 'yellow',
};


const Campaign = () => {
  const { t } = useTranslation()
  const cardList = [
    {
      id: 1,
      image: campaignImage1,
      title: 'SYNTREND-三創生活',
      description: t('The leading lifestyle and technology park and the venue of NFT Taipei conference. Dream Pass NFT can be redeemed as coupons to all the stores in Syntrend.'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid at Syntrend Creative Park'),
    },
    {
      id: 2,
      image: campaignImage2,
      title: 'WeMo',
      description: t('WeMo Scooter ride discount! Whether you are a first-timer or a seasoned rider, use Dream Pass NFT to redeem a discount code for WeMo Scooter and enjoy zipping through Taipei City, New Taipei City and Kaohsiung City!'),
      dotColor: DOT_COLOR.yellow,
      location: t('Valid in Taipei City, New Taipei City and Kaohsiung City'),
    },
    {
      id: 'cloneX',
      image: campaignCloneX,
      title: t('CloneX #9453 Vending Machine'),
      description: t('CloneX smart vending machine is going to be at Sytrend on 12/15-16. Come say hi and redeem a special gift.'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid at NFT Taipei conference(Dec. 15 ~ 16, 2022 Syntrend 5F)'),
    },
    {
      id: 3,
      image: campaignImage3,
      title: 'Halfway to Future 藝飲而浸',
      description: 'Redeem "Halfway to Future" bar hop ticket with the face value of NTD$500. The event covers more than 10 bars in Taipei, and there\'s a chance to get a limited NFT!',
      dotColor: DOT_COLOR.yellow,
      location: 'Valid in Taipei City',
      hidden: true,
    },
    {
      id: 4,
      image: campaignImage4,
      title: 'KKDay',
      description: t('KKday is the leading e-commerce traveling platform; redeem discounts for flights or overseas group expeditions on KKday with Dream Pass NFT. It\'s time to travel again!'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid on KKday website'),
    },
    {
      id: 5,
      image: campaignImage5,
      title: 'Taihu Brewing-臺虎精釀',
      description: t('Taihu Brewing has been leading the craft beer scene in Taiwan! Come to 5F at Sytrend on 12/15-16 to redeem a free Taihu beer that celebrates the flavors of Taiwan.'),
      dotColor: DOT_COLOR.yellow,
      location: t('Valid at NFT Taipei conference(Dec. 15 ~ 16, 2022 Syntrend 5F)'),
    },
    {
      id: 6,
      image: campaignImage6,
      title: 'Play Go with HeiJiaJia-黑嘉嘉圍棋教室',
      description: t('HeiJiaJia-Description'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid on HeiJiaJia website'),
    },
    {
      id: 7,
      image: campaignImage7,
      title: t('E-voucher VIP mystery box'),
      description: t('Dream Pass NFT holders can redeem e-vouchers from more than 10,000 stores on the largest e-voucher company in Taiwan.'),
      dotColor: DOT_COLOR.yellow,
      location: t('Valid at selected retail stores such as SK Mitsukoshi, Sogo, 7-11, Eslite Bookstore, etc'),
    },
    {
      id: 8,
      image: campaignImage8,
      title: t('E-voucher General mystery box'),
      description: t('In this box, there are $200 vouchers from 10 retail stores, $50 coupons from Eslite Bookstore, $25 off from the major convenience stores and much more!'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid at selected retail stores such as SK Mitsukoshi, Sogo, 7-11, Eslite Bookstore, etc'),
    },
    {
      id: 'traversel',
      image: campaignTravelverse,
      title: 'Travelverse 3.0',
      description: t('Travelverse 3.0 is gonna be a night of food, drinks, and great conversations! Redeem for an entry ticket and more goodies to be redeemed at the event!'),
      dotColor: DOT_COLOR.yellow,
      location: t('Valid at Taiwan Tech Arena（Dec. 13, 2022）'),
    },
    {
      id: 'astrogator',
      image: campaignAstroGater,
      title: 'AstroGater',
      description: t('A lucky draw for AstroGator swags! There are 5 t-shirts and 2 Genesis NFTs to be redeemed! Redeem to see if you are the winner!'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid on RE:DREAMER redeem page'),
    },
    {
      id: 'iCarry',
      image: campaignICarry,
      title: 'iCarry',
      description: t('GivDao\'s booth will be at Sytrend from 12/15-18. Visit our booth and redeem a party gift from YuDuan!'),
      dotColor: DOT_COLOR.yellow,
      location: t('Valid at Syntrend Creative Park（Dec. 15 ~ 18, 2022）'),
    },
    {
      id: 'DrunkWolverine',
      image: campaignDrunkWolverine,
      title: 'Drunk Wolverine',
      description: t('3,000 participants can redeem a unique collection of Drunk Wolverine POAP from TheWolverinePack partnering with ThunderCore!'),
      dotColor: DOT_COLOR.purple,
      location: t('Valid on RE:DREAMER redeem page'),
    },
    {
      id: 9,
      image: placeholderImage,
      title: t('More to come'),
      description: t('Stay tuned and follow us on social to keep up to date!'),
      dotColor: DOT_COLOR.yellow,
      location: '-',
    },
  ].filter((l) => !l.hidden);

  const [collapse, setCollapse] = useState(true);
  const preList = cardList.slice(0, 6);
  const postList = cardList.slice(6);
  return (
    <StyledContainer id="campaigns">
      <StyledContent>
        <StyledTitleContainer>
          <StyledTitle>{t('Dream Pass Campaigns')}</StyledTitle>
          <StyledDescription>
            {t('Owning a Dream Pass, you can enjoy the following exciting values not only in Taipei')}
          </StyledDescription>
        </StyledTitleContainer>
        <StyledCardContainer>
          {preList.map((campaign) => (
            <Card
              key={campaign.id}
              title={campaign.title}
              image={campaign.image}
              description={campaign.description}
              dotColor={campaign.dotColor}
              location={campaign.location}
            />
          ))}
          {!collapse &&
            postList.map((campaign) => (
              <Card
                key={campaign.id}
                title={campaign.title}
                image={campaign.image}
                description={campaign.description}
                dotColor={campaign.dotColor}
                location={campaign.location}
              />
            ))}
        </StyledCardContainer>
        {postList.length > 0 && (
          <ExternalButton
            onClick={() => {
              setCollapse(!collapse);
            }}
          >
            {collapse ? t('SEE MORE') : t('SHOW LESS')}
          </ExternalButton>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default Campaign;
