import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { XS_QUERY } from '../../configs/breakpoints';

const HEIGHT = 80;

const StyledContainer = styled.div`
  padding-top: ${HEIGHT}px;
`;

const StyledContent = styled.div`
  position: absolute;
  bottom: 0;
  color: ${({ color }) => color};
  border-top: 1px solid ${({ theme }) => theme.colors.brainGreyColor};
  font-size: 14px;
  text-align: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  line-height: 20px;

  @media ${XS_QUERY} {
    justify-content: flex-start;
    padding-right: 12px;
    padding-left: 12px;
  }
  
  .empower {
    position: absolute;
    right: 20px;

    @media ${XS_QUERY} {
      width: 100px;
      right: 12px;
      width: 16vw;
    }
  }
`;

const date = new Date();
const year = date.getFullYear();

const Footer = ({ className, color }) => (
  <StyledContainer className={className}>
    <StyledContent color={color}>
      Copyright&nbsp;&copy;&nbsp;
      {year}
      &nbsp;
      RE:DREAMER. All rights reserved.
    </StyledContent>
  </StyledContainer>
);

Footer.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Footer.defaultProps = {
  className: undefined,
  color: 'white',
};

export default Footer;
